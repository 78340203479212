@import "variables";

.cta-container {
  cursor: pointer;

  .menu {
    display: none;
    position: absolute;
    min-width: 100px;
    padding: 5px 0;
    border: .5px solid #c3c3c3;
    border-radius: 4px;
    background: rgba(238, 238, 238, .95);
    box-shadow: 0 4px 4px rgba(0, 0, 0, .12), 0 1px 1px rgba(0, 0, 0, .06), 0 2px 2px rgba(0, 0, 0, .04), 0 4px 4px rgba(0, 0, 0, .03), 0 8px 8px rgba(0, 0, 0, .01);
    list-style: none;

    li {
      button {
        min-width: 150px;
        padding: 3px 16px;
        color: $black;
        text-align: left;
        text-decoration: none;
        white-space: nowrap;

        &:hover {
          background-color: #0270e3;
          color: #fff;
        }

        &.danger {
          color: #e2184a;

          &:hover {
            background-color: #e2184a;
            color: #fff;
          }
        }
      }
    }

    oz-cta.warning li button {
      color: #e2184a;
    }
  }
}

/* specific positioning cases */
oz-cta.bottom-right .cta-container {
  position: absolute;
  right: 30px;
  bottom: 30px;

  .menu {
    right: 6px;
    bottom: 10px;
  }
}

.favorites oz-cta .cta-container {
  display: inline-block;
  transform: translateY(17%);

  .menu {
    top: 6px;
    left: 6px;
  }
}
