@import "variables";

.modal {
  display: flex;
  position: fixed;
  z-index: -999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  transition: opacity .25s;
  opacity: 0;
  background: rgba(0,0,0,.7);

  &.show {
    z-index: 999;
    opacity: 1;
  }

  .modal-content {
    max-width: 450px;
    max-height: calc(90vh - #{$title-bar-height});
    margin-top: $title-bar-height;
    padding: 20px;
    overflow-y: auto;
    border-radius: 8px;
    background-color: $white;

    &.wide {
      width: 520px;
      max-width: 520px;
    }

    h1 {
      margin: 0 0 1em 0;
      font-size: 17px;
      font-weight: 700;
      line-height: 17px;
    }
  }
}
