router-outlet,
oz-animated-router-outlet {
  display: block;
}

oz-icon {
  display: inline-block;
}

oz-loading-indicator {
  display: inline-block;
}
