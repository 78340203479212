@import "~typeface-lato/index.css";

@import "partials/reset";

@import "partials/typography";

@import "partials/animation";

@import "partials/angular";

@import "partials/grid";

@import "partials/layout";

@import "partials/titlebar";

@import "partials/control-bar";

@import "partials/form";

@import "partials/accordion";

@import "partials/card";

@import "partials/utilities";

@import "partials/custom";

@import "partials/variables";

@import "partials/images";

@import "partials/sidebar";

@import "partials/stepper";

@import "partials/progress";

@import "partials/cta";

@import "partials/speed-indicator";

@import "partials/slider";

@import "partials/video_controls";

@import "partials/badge";

@import "partials/modal";

@import "partials/toast";

@import "partials/alpha_feedback";

@import "partials/table";

/**
=== DO NOT ADD STYLES HERE ===
Styles belong in the partial files listed above.

If you are unsure where to put them, put them in the _custom.scss file.

=== DOCUMENTATION ===
Documentation is available in this repo at /docs/index.html

=== DO's and DON'Ts ===
NO COMPONENT CSS
NO <style> WITHIN TEMPLATE FILES (.html or .pug files)
DO NOT add styles to this file
DO ADD CUSTOM STYLES TO THE "_custom.scss" PARTIAL

DO NOT AUTHOR CUSTOM STYLES JUST TO GET MARGIN / PADDING.
  (instead, use the "UTILITY CLASSES" described below)
DO NOT AUTHOR CUSTOM STYLES FOR LAYOUT.
  (Layout should be achievable using the "GRID" classes described below)
*/

// DO NOT ADD STYLES HERE
