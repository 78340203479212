@import "./variables";

* {
  margin: 0;
  padding: 0;
}

html,
body,
app-root {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: $font-family;
  font-size: $font-md;
  font-weight: $font-weight-normal;
  line-height: 1.5;
}

a,
img {
  -webkit-user-drag: none;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;

  -webkit-appearance: textfield;
}

// Remove the inner padding in Chrome and Safari on macOS.
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

summary {
  display: list-item;
}

details summary::-webkit-details-marker {
  display: none;
}

button,
button > * {
  cursor: pointer;
}
