.progress {
  position: relative;
  height: 2px;
  margin-bottom: 8px;
  overflow: hidden;
  border-radius: 2px;
  background-color: #ccc;
}

.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 2px;
  border-radius: 2px;
  background-color: #0270e3;
}
