@import "./variables";

@import "./images";
$BUTTON_COLOR: rgb(170, 170, 170);

label {
  font-weight: $font-weight-bold;
}

input,
select,
button {
  &:focus {
    // TODO discuss with UX the accessibility implications & design for this
    //outline: none;
  }
}

// NOTE: Figma has an odd number (16.75px, which rounds to 17px).
// Need to use an even number for "smooth" rendering of borders.
$switch-height: 18px;
$switch-border-color: #707070;
$switch-width: 34px;
$switch-indicator-height: $switch-height;

// NOTE: If changing these, be sure to check in Poly Settings, Health & Wellness, and Device settings
.toggle-switch {
  display: inline-block;
  position: relative;
  height: $switch-height;

  input[type="checkbox"] {
    @extend %sr-only;
  }

  .slider {
    display: block;
    position: relative;
    box-sizing: border-box;
    width: $switch-width;
    height: $switch-height;
    border: 1px solid $switch-border-color;
    border-radius: calc( #{$switch-height} / 2);
    background-color: #eee;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, .25);
    cursor: pointer;

    .button {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      box-sizing: border-box;
      width: $switch-indicator-height;
      height: $switch-indicator-height;
      transition-property: left;
      transition-timing-function: ease;
      border: 1px solid $switch-border-color;
      border-radius: 50%;
      background-color: $white;
    }
  }

  input:checked + .slider {
    background-color: $black;

    .button {
      left: $switch-width - $switch-indicator-height - 1px;
    }
  }

  &.disabled {
    &:hover,
    .slider:hover,
    .button:hover {
      cursor: not-allowed !important;
    }
  }
}

::placeholder {
  color: $light-gray;
}

.checkbox {
  display: none;

  + label {
    display: inline-block;
    position: relative;
    padding: 9px;
    border: 1px solid #707070;
    border-radius: 3px;
    background-color: transparent;
  }

  &:checked {
    + label {
      border: 1px solid #000;
      background-color: #000;

      &:after {
        content: url($checkmark-svg);
        position: absolute;
        top: 0;
        left: 2px;
        color: #fff;
        font-size: 14px;
      }
    }
  }
}

.dropdown-options {
  max-width: 200px;
  height: 25px;
  padding: 0 20px 0 4px;
  border: none;
  border-bottom: 1px solid $light-gray;
  border-radius: 0;
  background: $white;
  background: url($down-arrow-svg) no-repeat right;
  font-family: $font-family;
  font-size: $font-md;
  text-overflow: ellipsis;

  -webkit-appearance: none;

  &.dropdown-form-control {
    display: block;
    width: 100%;
  }

  &:focus {
    outline: none;
  }
}

.dropdown-select-placeholder {
  display: none;
}

.multiselect-dropdown {
  display: inline-block;
  position: relative;
  width: 120px;

  &.dropdown-form-control {
    width: 100%;
  }

  &:focus {
    outline: none;
  }

  .over-select {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  > .multiselect-dropdown-options-container {
    position: fixed;
    z-index: 1000;
    max-height: 250px;
    overflow-x: hidden;
    overflow-y: auto;
    border: 1px solid #c2ab23;
    background: $white;
  }
}

.dropdown-with-icons {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: fit-content;

  .dropdown-select {
    position: relative;
    z-index: 0;

    .input-select {
      display: inline-block;
      position: relative;
      box-sizing: border-box;
      padding: 0;
      border: none;
      border-bottom: 1px solid $light-gray;
      border-radius: 0;
      background-color: inherit;
      font-family: $font-family;
      text-overflow: ellipsis;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      &.disabled {
        opacity: $disabled-opacity;
        cursor: not-allowed;
      }
    }

    .arrow-down {
      position: absolute;
      z-index: -1;
      top: 3px;
      right: 0;
      cursor: pointer;
    }

    .icons {
      position: absolute;
      z-index: -1;
      top: 0;
      right: 25px;
      text-align: right;
      white-space: nowrap;
      cursor: pointer;

      .icon {
        display: inline;
        margin-left: 3px;
        cursor: pointer;
      }
    }
  }

  .dropdown-list {
    position: absolute;
    z-index: 999;
    top: -8px;
    left: -20px;
    padding: 10px 0;
    border-radius: 4px;
    background-color: $white;
    box-shadow: $dropdown-box-shadow;
    text-align: left;

    .arrow-up {
      position: absolute;
      top: 12px;
      right: 20px;
    }

    &:focus {
      outline: none;
    }

    .options-wrapper {
      max-height: 200px;
      overflow-y: overlay;

      hr {
        height: 1px;
        margin: 3px 20px;
        padding: 0;
        border: none;
        outline: none;
        background-color: $light-gray;
      }
    }

    .option {
      display: flex;
      padding: 4px 20px;
      cursor: pointer;

      &.selected {
        .option-label {
          margin-left: -5px;
        }

        &::before {
          content: "";
          display: inline-block;
          position: relative;
          z-index: 50;
          top: 8px;
          left: -12px;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: $light-gray;
        }
      }

      .option-label {
        flex: 1;
        max-width: 220px;
        padding-right: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .option-icons {
        padding-right: 25px;
        line-height: 0;
        text-align: right;
        white-space: nowrap;

        .option-icon {
          display: inline;
          margin-left: 5px;
        }

        &.no-padding {
          padding-right: 0;
        }
      }

      &.placeholder {
        margin: 0 20px 10px 20px;
        padding: 0 0 2px 0;
        border-bottom: 1px solid $hr-color;
        cursor: pointer;

        &:hover {
          background-color: inherit;
          color: inherit;
        }
      }

      &:hover {
        background-color: $link-color;
        color: $white;
      }
    }
  }
}

// Hack to use custom dropdown when it is aligner right
.text-right {
  .dropdown-with-icons {
    .dropdown-list {
      right: -20px;
      left: inherit;
    }
  }
}

.btn {
  display: inline-block;
  padding: 4px 12px;
  border: 1px solid black;
  border-radius: 5px;
  background: $white;
  font-size: $font-xs;
  font-weight: 700;
  letter-spacing: .5px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  user-select: none;

  &[disabled] {
    opacity: .8;
    cursor: not-allowed;
  }

  &.btn-wizard {
    border: none;
    outline: none;
    background-color: transparent;
    font-size: $font-md;
    font-weight: normal;

    &.btn-wizard-next {
      &::after {
        content: "";
        display: inline-block;
        position: relative;
        left: 2px;
        width: 5px;
        height: 5px;
        transform: rotate(45deg);
        border: 2px solid;
        border-radius: 1px;
        border-color: $BUTTON_COLOR $BUTTON_COLOR transparent transparent;
      }
    }

    &.btn-wizard-back {
      &::before {
        content: "";
        display: inline-block;
        position: relative;
        left: 0;
        width: 5px;
        height: 5px;
        margin-right: 4px;
        transform: rotate(-135deg);
        border: 2px solid;
        border-radius: 1px;
        border-color: $BUTTON_COLOR $BUTTON_COLOR transparent transparent;
      }
    }
  }
}

.btn-primary {
  display: inline-block;
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  background: $button-background;
  color: $white;
  font-weight: $font-weight-bold;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
}

.btn-secondary {
  border: 1px solid;
  border-color: $button-background;
  background: $white;
  color: $button-background;@extend .btn-primary;
}

.btn-large {
  padding: 10px 32px;
  font-size: $font-md;
}

.link {
  padding: 0;
  border: none;
  background: transparent;
  color: #464646;
  text-decoration: underline;
  user-select: none;

  &:focus {
    outline: none;
  }

  &.no-underline {
    text-decoration: none;
  }
}

.link-primary {
  display: inline-block;
  padding: 5px 0;
  border: none;
  background: transparent;
  color: $link-color;
  font-size: $font-md;
  font-weight: $font-weight-bold;
  cursor: pointer;
  user-select: none;

  &:focus {
    outline: none;
  }

  &.underline {
    font-size: $font-lg;
    text-decoration: underline;
  }

  &.normal {
    font-weight: $font-weight-normal;
  }
}

.btn,
.btn-primary,
.link,
.link-primary {
  &:disabled {
    opacity: .6;
  }

  &:disabled,
  &:disabled > * {
    cursor: not-allowed;
  }

  .with-icon {
    display: block;
    position: relative;

    oz-icon {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
    }

    .btn-label {
      display: inline-block;
      position: relative;
      z-index: 1;

      &.show-icon {
        padding-left: 18px;
      }
    }
  }
}

.btn-rounded {
  display: inline-block;
  padding: 5px 12px;
  border: 1px solid $mid-gray;
  border-radius: 20px;
  background: transparent;
  color: $mid-gray;
  font-size: $font-sm;
  font-weight: $font-weight-bold;
  text-decoration: none;
  cursor: pointer;
  user-select: none;

  &:disabled,
  &.selected {
    border: 1px solid $black;
    background: $black;
    color: $white;
  }

  &:focus {
    outline: none;
  }
}

.mat-input {
  border: none;
  border-bottom: 1px solid #707070;
  background: transparent;

  input {
    width: 100%;
    padding: 4px 8px;
    border: none;
    background: transparent;
    color: #707070;

    &:focus {
      outline: none;
    }
  }

  oz-icon + input,
  .oz-icon + input {
    box-sizing: border-box;
    width: calc(100% - 25px);
  }
}

.form-control {
  display: inline-block;
  width: 100%;
  padding: 3px 5px;
  border: 1px solid #888;
  border-radius: 2px;
}

oz-input-with-confirm {
  display: block;
  width: 100%;

  .mat-input {
    display: inline-block; /* match width with sister oz-slider components */
    width: $favorites-width;

    input {
      width: calc(100% - 8px);
      padding: 4px;
    }
  }

  .confirm {
    margin: 0 4px 0 13px;
  }

  .confirm,
  .reject {
    position: relative;
    top: 3px;
  }
}

oz-input-password {
  position: relative;
  top: -3px;

  input {
    width: calc(100% - 25px);
    padding-right: 25px !important;
    background: none !important;
  }

  oz-icon {
    position: absolute;
    top: 2px;
    right: 0;
  }
}

.favorites oz-dropdown select {
  width: $favorites-width;
}

input[type="text"],
input[type="password"] {
  height: 25px;
  padding: 0 4px 0 4px;
  border: none;
  border-bottom: 1px solid $light-gray;
  border-radius: 0;
  background: $white;
  font-family: $font-family;
  font-size: $font-md;

  &:focus {
    outline: none;
  }

  &.transparent {
    background: none;
  }
}

input[type="time"] {
  height: 25px;
  padding: 0;
  border: none;
  border-bottom: 1px solid $light-gray;
  border-radius: 0;
  background: $white;
  font-family: $font-family;
  font-size: $font-md;

  /* removes clock button from the input control */
  &::-webkit-calendar-picker-indicator {
    display: none;
    background: none;
  }

  &::-webkit-datetime-edit-hour-field {
    color: $black;
  }

  &::-webkit-datetime-edit-minute-field {
    color: $black;
  }

  &:focus {
    outline: none;
  }
}

.up-down-buttons {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50px;
  height: 17px;

  button {
    display: inline-block;
    position: relative;
    width: 19px;
    height: 17px;
    margin: auto;
    padding-top: -20px;
    border: none;
    border-radius: 4px;
    outline: none;
    background-color: #000;
    color: white;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
  }
}

oz-buttongroup {
  display: inline-block;
  border-radius: 8px;
  background-color: $white;
  box-shadow: $card-box-shadow;

  button,
  oz-buttongroup-button {
    display: inline-block;
    margin: 0;
    padding: 8px 16px;
    border: none;
    border-radius: 8px;
    opacity: .3;
    background: $white;
    box-shadow: none;
    color: $black;
    font-size: $font-sm;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    user-select: none;

    &:focus {
      outline: none;
    }

    &:hover {
      opacity: .8;
    }

    &.selected {
      opacity: 1;
      background: #f1f2f3;
      box-shadow: 0 4px 4px rgba(0, 0, 0, .2);
    }

    &:disabled,
    &.disabled {
      opacity: .3;
      background: white;
      box-shadow: none;
      cursor: not-allowed;
    }
  }
}
