@import "variables";

.control-bar {
  .poly-controls {
    height: 24px;
    padding: 20px 0;
    align-items: center;

    button {
      border: none;
      padding: 2px 4px;
      background: none;
    }

    .control {
      &.back-link {
        font-size: $font-sm;
        transform: translateY(-6px);
      }

      &.connect {
        justify-content: flex-start;
        padding: 5px 15px;
        border: none;
        border-radius: 5px;
        background: $button-background;
        color: $white;
        font-size: $font-sm;
        font-weight: $font-weight-normal;
        text-decoration: none;
        cursor: pointer;
        user-select: none;
        float: right;
      }
    }
  }
}

