@import "./variables";

.side-bar {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  width: $sidebar-width;
  height: $layout-height;
  padding: 0 20px;
  background-color: $white;
  color: #333;

  .spacer {
    flex-grow: 1;
    min-height: 25px;
    max-height: $global-padding-top;
  }

  .container {
    flex-grow: 1;
    width: 250px;
    overflow-x: visible;
    overflow-y: auto;

    ul {
      padding: 0;
      list-style: none;

      li {
        margin: 5px 0;
        padding: 7px;
        border-radius: 5px;
        font-size: 14px;

        &:hover {
          margin-right: 8px;
          background-color: rgb(247, 248, 249);
        }

        &.active {
          margin-right: 8px;
          background-color: rgb(247, 248, 249);
        }

        a {
          display: flex;
          width: 100%;
          color: $black;
          text-decoration: none;

          &.home {
            .icon {
              height: 17.5px;
            }

            .label {
              line-height: 1.3;
            }
          }

          &.disconnected {
            color: $mid-gray;
          }
        }

        .icon,
        .action {
          width: 20px;
        }

        .label {
          width: 170px;
          margin-right: 10px;
          font-size: 14px;
        }

        .primary-control {
          min-width: 12px;
        }
      }
    }

    .cactus {
      display: inline-grid;
      margin: 60px 0 20px 0;

      a,
      p {
        max-width: 125px;
        margin: 0 auto;
        padding-top: 30px;
        color: #666;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        text-decoration: none;

        &.no-device {
          max-width: 150px;
          margin: 0 auto;
          margin-top: 60px;
          padding: 8px 48px;
          border-radius: 5px;

          &:hover {
            background-color: rgb(247, 248, 249);
          }

          &.active {
            background-color: rgb(247, 248, 249);
          }
        }
      }
    }
  }
}

.logo-container {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 20px 15px 25px;
  align-items: center;
}
